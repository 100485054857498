<template>
  <div class="mobile-kits">
    <div class="mobile-kits-title">
      <h4>My Kits</h4>
      <p>You can see and edit your uploaded files from below</p>
    </div>
    
    <div class="mobile-kits-body">
      <div class="dashboard-card mobile-dashboard-item" v-for="item in kits" :key="item.guid">
        <div class="item-line">
          <div class="item-info">
            <h5>Name</h5>
            <h6>{{item.resultTitle}}</h6>
          </div>
          <div class="item-info">
            <h5>Service</h5>
            <h6>{{item.serviceName}}</h6>
          </div>
        </div>
        <div class="item-line">
          <div class="item-info">
            <h5>File</h5>
            <h6>{{item.resultDescription}}</h6>
          </div>
          <div class="item-info text-right">
            <span  @click="editKit(item)"><circle-edit-icon class="cursor-pointer"/></span>
          </div>
        </div>
      </div>
    </div>

    <edit-kit-modal :modalObject="editObject" v-if="editObject.showModal"/>
    <delete-kit-modal :modalObject="deleteObject" v-if="deleteObject.showModal"/>
  </div>
</template>

<script>
import CircleEditIcon from '@/layouts/icons/CircleEditIcon.vue';
import EditKitModal from './EditKitModal.vue';
import DeleteKitModal from './DeleteKitModal.vue';

export default {
  components: {
    EditKitModal,
    DeleteKitModal,
    CircleEditIcon,
  },
  data() {
    return {
      editObject: {
        item: null,
        showModal: false,
        onSuccess: this.fetchKits,
      },
      deleteObject: {
        item: null,
        showModal: false,
        onSuccess: this.fetchKits,
      },
    }
  },
  methods: {
    fetchKits() {
      this.$store.dispatch('orders/fetchKits', {});
    },

    editKit(kit) {
      this.editObject.item = { ...kit };
      this.editObject.showModal = true;
    },

    deleteKit(kit) {
      this.deleteObject.item = { ...kit };
      this.deleteObject.showModal = true;
    },
  },
  computed: {
    kits() {
      return this.$store.getters['orders/getKits'];
    },
  },
  created() {
    this.fetchKits();
  },
}
</script>

<style scoped lang="scss">
.mobile-kits-title {
  padding: 0.5rem 1.5rem;

  h4 {
    color: var(--lab-carbon-fiber);
  }
  p {
    color: var(--lab-stieglitz-silver);
  }
}

.dark-layout {
  .mobile-kits-title h4 {
    color: var(--lab-lago-blue-color);
  }
  .mobile-kits-title p {
    color: var( --lab-doctor);
  }
}
</style>
