<template>
  <svg :id="$store.state.appConfig.layout.skin" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 39">
    <defs>
    </defs>
    <g id="Layer_2-2" data-name="Layer 2">
      <g>
        <path class="cls-1" d="M19.5,39C8.75,39,0,30.25,0,19.5S8.75,0,19.5,0s19.5,8.75,19.5,19.5-8.75,19.5-19.5,19.5Zm0-36C10.4,3,3,10.4,3,19.5s7.4,16.5,16.5,16.5,16.5-7.4,16.5-16.5S28.6,3,19.5,3Z"/>
        <path class="cls-2" d="M18.94,13.42l-7.86,7.86c-.16,.16-.27,.36-.31,.58l-1.24,5.92c-.08,.38,.04,.77,.31,1.04,.27,.27,.66,.39,1.04,.32l5.96-1.19c.22-.04,.43-.15,.59-.31l7.86-7.86-6.35-6.35h0Zm1.62-1.62l6.35,6.35,1.28-1.28c1.75-1.75,1.75-4.59,0-6.35,0,0,0,0,0,0-1.75-1.75-4.59-1.75-6.35,0l-1.28,1.28Z"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
svg {
  height: 30px;
}

.cls-2 {
  fill-rule: evenodd;
}

#dark .cls-1, #dark .cls-2 {
  fill: var(--lab-lago-blue-color);
}
#light .cls-1, #light .cls-2 {
  fill: var(--lab-carbon-fiber);
}
</style>
